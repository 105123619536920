/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Snorkel from 'img/snorkel_landing_5.svg'
import SnorkelSurvival from 'img/survival-zwemmen-snorkeltje.svg';

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomLink from 'components/CustomLink'
import UBOSelect from 'components/UBOSelect'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const ImgContainer = styled.div`
  width: 100%;
  height: 1200px;
  position: relative;
  /* margin-top: -70px; */

  @media screen and (max-width: 991px) {
    height: 800px;
    margin-top: 0;
  }

  @media screen and (max-width: 484px) {
    height: 700px;
  }
`

const SnorkelImg = styled.img`
  position: absolute;
  height: 400px;
  left: 200px;

  @media screen and (max-width: 991px) {
    left: 0;
    height: 350px;
    width: 220px;
  }

  @media screen and (max-width: 576px) {
    left: 30px;
    height: 300px;
    width: 170px;
  }
`

const SnorkelContainer = styled.div`
  height: 100%;
`

const Test = styled.div`
  position: absolute;
  bottom: -540px;
  display: flex;
  height: 100%;

  @media screen and (max-width: 1199px) {
    left: -120px
  }

  @media screen and (max-width: 991px) {
    bottom: -300px;
    left: 80px
  }

  @media screen and (max-width: 767px) {
    bottom: -300px;
    left: 0;
  }

  @media screen and (max-width: 576px) {
    bottom: -340px;
  }

  @media screen and (max-width: 484px) {
    bottom: -280px;
  }

  @media screen and (max-width: 415px) {
    left: -30px;
  }
`

const Bubble1 = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.72);
  height: 175px;
  width: 175px;
  border-radius: 100%;
  left: 460px;
  top: -250px;
  position: absolute;

  & a {
    text-decoration: none !important; 
  }

  & p {
    line-height: 25px;
    color: ${props => props.theme.color.text.main};
    
  }

  @media screen and (max-width: 991px) {
    height: 130px;
    width: 130px;
    left: 220px;
    top: -20px;

    p {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  @media screen and (max-width: 576px) {
    left: 180px;
    top: -60px;
  }

  @media screen and (max-width: 484px) {
    left: 100px;
    top: -30px;
  }

  @media screen and (max-width: 390px) {
    left: 80px;
    top: -20px;
  }

  @media screen and (max-width: 325px) {
    left: 60px;
    top: -20px;
  }
`

const Bubble2 = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.72);
  height: 63px;
  width: 63px;
  border-radius: 100%;
  left: 500px;
  top: -30px;
  position: absolute;

  & p {
    line-height: 25px;
    color: ${props => props.theme.color.text.light};
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Bubble3 = styled(motion.div)`
  background-color: rgba(255, 229, 0, 0.72);
  height: 143px;
  width: 143px;
  border-radius: 100%;
  left: 650px;
  top: -120px;
  position: absolute;

  & a {
    text-decoration: none !important; 
  }

  & p {
    line-height: 22px;
    color: ${props => props.theme.color.text.main};
  }

  @media screen and (max-width: 991px) {
    left: 350px;
    top: 80px;
    height: 100px;
    width: 100px;

    p {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  @media screen and (max-width: 576px) {
    left: 300px;
    top: 60px;
  }

  @media screen and (max-width: 484px) {
    left: 250px;
    top: 60px;
  }

  @media screen and (max-width: 390px) {
    left: 220px;
    top: 60px;
  }

  @media screen and (max-width: 325px) {
    left: 180px;
    top: 70px;
  }
`

const Bubble4 = styled(motion.div)`
  background-color: rgba(255,92,0,0.82);
  width: 180px;
  height: 180px;
  border-radius: 100%;
  left: 680px;
  top: -350px;
  position: absolute;

  & p {
    line-height: 30px;
    color: ${props => props.theme.color.text.light};
  }

  & a {
    text-decoration: none !important; 
  }

  @media screen and (max-width: 991px) {
    width: 170px;
    height: 170px;
    left: 200px;
    top: -240px;

    p {
      font-size: ${props => props.theme.font.size.xm};
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 576px) {
    left: 180px;
    top: -250px;
  }

  @media screen and (max-width: 484px) {
    left: 80px;
    top: -240px;
  }

  @media screen and (max-width: 390px) {
    left: 60px;
    top: -220px;
  }

  @media screen and (max-width: 325px) {
    left: 45px;
    top: -240px;
  }
`

const Bubble5 = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.72);
  width: 230px;
  height: 230px;
  border-radius: 100%;
  left: 840px;
  top: -210px;
  position: absolute;

  @media screen and (max-width: 991px) {
    width: 160px;
    height: 160px;
    left: 360px;
    top: -110px;

    .react-select__placeholder {
      font-size: ${props => props.theme.font.size.xm} !important;
    }

    .react-select-container {
      width: 130px;
    }
  }

  @media screen and (max-width: 576px) {
    left: 320px;
    top: -120px;
  }

  @media screen and (max-width: 484px) {
    left: 250px;
    top: -125px;
  }

  @media screen and (max-width: 390px) {
    left: 220px;
    top: -115px;
  }

  @media screen and (max-width: 325px) {
    left: 185px;
    top: -115px;
  }
`

const StyledVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
`

const VideoWrapper = styled.div`

  @media screen and (min-width: 992px) {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    overflow: hidden;
  }

  @media screen and (max-width: 991px) {
    position: absolute;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    max-height: unset;
    max-width: unset;
    overflow: unset;
    left: 50%;
    transform: translate(-50%);
  }
`

const SnorkelSurvivalImg = styled.img`
    width: 300px;
    height: 300px;
    position: absolute;
    left: 80%;
    top: 6%;

    @media (max-width: 1399.98px) {}
    @media (max-width: 1199.98px) {
      top: 10%;
    }
    @media (max-width: 991.98px) {
      top: 8%;
      left: 120%;
    }
    @media (max-width: 767.98px) {
      left: 60%;
      width: 220px;
    }
    @media (max-width: 575.98px) {
      width: 212px;
      left: 35%;
      top: 4%;
    }

    @media (max-width: 460px) {
      top: -1%;
      left: 57%;
    }
`;

function HomePage() {
  const {page: { yoastMeta, path }, locations: { edges: locations } } = useStaticQuery(graphql`
      query {
        page: wordpressPage(wordpress_id: {eq: 306}) {
          path
          yoastMeta: yoast_meta {
            name
            content
            property
          }
        }
        locations: allWordpressWpLocaties {
          edges {
            node {
              slug
              title
              acf {
                location_title
                secondary_location
              }
            }
          }
        }
      }
  `)

const options = []

locations.forEach(({ node }) => {
  const subLocations = locations.filter(({ node: { acf: { location_title: locationTitle } } }) => locationTitle === node.acf.location_title)
  const edges = { label: node.acf.location_title, value: `/locatie/${node.slug}` }

  if(options.find(({ label }) => label === node.acf.location_title) === undefined) {
    if(subLocations.length > 1) {
      options.push({ ...{ options: subLocations.map(({ node: subNode }) => { return { label: subNode.acf.secondary_location, value: `/locatie/${subNode.slug}` } }) }, ...edges })
    } else {
      options.push(edges)
    }
  }
})

  return (
    <Layout partialHeader scalableFooter>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <div className="home-page">
        <ImgContainer>
          <VideoWrapper>
            <StyledVideo autoPlay muted loop playsInline>
              <source type="video/mp4" src="https://player.vimeo.com/external/430694949.hd.mp4?s=fb7d3181a89db7b500942bc9f3bad0ea89a74e05&profile_id=174&download=1" />
            </StyledVideo>
          </VideoWrapper>
    
          <SnorkelContainer className="container position-relative">
            <div className="row">
              <Test className="col-sm-6">
                <SnorkelImg loading="eager" fadeIn={false} src={Snorkel} alt="" />
                <SnorkelSurvivalImg loading='eager' src={SnorkelSurvival} alt="" />
                <Bubble1 animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.5, opacity: 0 }} transition={{ delay: .6, duration: 0.5 }} className="d-flex align-items-center justify-content-center">
                  <CustomLink external newPage to="https://snorkeltje.i-reserve.net/">
                    <p className="mb-0 font-weight-xl font-size-l text-center">Reserveren / afmelden zwemles</p>
                  </CustomLink>
                </Bubble1>
                <Bubble2 animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.3, opacity: 0 }} transition={{ duration: 0.7 }} />
                <Bubble3 animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.5, opacity: 0 }} transition={{ delay: .3, duration: 0.5 }} className="d-flex align-items-center justify-content-center">
                  <CustomLink to="/lesaanbod/mini-survival-zwemmen">
                    <p className="mb-0 px-lg-3 font-weight-xl font-size-l text-center">Mini-survival zwemles</p>
                  </CustomLink>
                </Bubble3>
                <Bubble4 animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.5, opacity: 0 }} transition={{ delay: 1.2, duration: 0.5 }} className="d-flex align-items-center justify-content-center">
                  <CustomLink to="/lesaanbod/abc-zwemles">
                    <p className="mb-0 font-weight-xl font-size-l text-center color-text-light">ABC<br/> Zwemles</p>
                  </CustomLink>
                </Bubble4>
                <Bubble5 animate={{ scale: [1, 1.2, 1], opacity: 1 }} initial={{ scale: 0.5, opacity: 0 }} transition={{ delay: .9, duration: 0.5 }} className="d-flex align-items-center justify-content-center">
                  <UBOSelect 
                    bigLabel
                    placeholder="Locaties"
                    options={options}
                  />
                </Bubble5>
              </Test>
            </div>
          </SnorkelContainer>
        </ImgContainer>
      </div>
    </Layout>
  )
}

export default HomePage
